@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Raleway";
    src: url("./assets/fonts/raleway-regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("./assets/fonts/raleway-italic.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Raleway";
    src: url("./assets/fonts/raleway-bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Raleway";
    src: url("./assets/fonts/raleway-bold-italic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
}

* {
    box-sizing: border-box;
}

body {
    box-sizing: border-box;
    overflow-x: hidden;
    max-width: 100vw;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%2300529B' fill-opacity='0.1'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

input[type="search"] {
    -webkit-appearance: none;
}

p a {
    text-decoration: underline;
}
